
import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
  state () {
    // sessionCode : null
    },
  mutations: {
  
  }
})

export default store